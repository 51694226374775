







import RatesList from '../../components/rates/RatesList.vue';
import Vue from 'vue'
export default Vue.extend({
  components: { RatesList },
    name: 'RatesListPage',
    metaInfo(){
      return  {
      title: "Rates List - Our Nation Express",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Welome to Our Nation Express. Our rates are second to NONE! Enjoy browsing through our rates list."
        }
      ]
    };
    } 
})
